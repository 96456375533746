import React from 'react';
import './training.css';
import { useNavigate } from 'react-router-dom';
import admin from '../../images/admin.jpg'

function Training() {
    const navigate = useNavigate();
    const handleNavigate = () => {
      navigate('/');
    }
  return (
    <div className='training'>
        <div className="training-cover">
            <div className="training-img">
                <img src={admin} alt="Office Assistant" />
            </div>
            <div className="training-content">
                <h1>Training</h1>
                <p>
                At Sharepoint Training & Consultancy, we offer a comprehensive range of training programs tailored to enhance individual and organizational performance. Our expertise includes Leadership & Management, Customer Experience, Sales & Marketing, MSME & Entrepreneurship, Team Building, Strategic Management, Financial Literacy, Business Growth & Development, Supervisory Skills, Emotional Intelligence, and Risk Management. Each program is designed to empower participants with practical skills and knowledge, driving business success and fostering sustainable growth.  
                </p>
                <button className='back-btn' onClick={handleNavigate}>Back Home</button>
            </div>
        </div>
    </div>
  )
}

export default Training;