import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './services.css'
import wf from '../../images/office1.jpg';
import Leadership from './Leadership';
import Customer from './Customer';
import Sales from './Sales';
import MSME from './MSME';
import Team from './Team';
import Strategic from './Strategic';
import Financial from './Financial';
import Business from './Business';
import Supervisory from './Supervisory';
import Inteligence from './Inteligence';
import Risk from './Risk';

function Services() {
  const navigate = useNavigate();
  const handleNavigate = () => {
    navigate('/');
  }

  // 

  const [activeComponent, setActiveComponent] = useState(null);

  const renderComponent = () => {
    switch (activeComponent) {
      case 'Leadership':
        return <Leadership closeChild={() => setActiveComponent(null)} />;
      case 'Customer':
        return <Customer closeChild={() => setActiveComponent(null)} />;
      case 'Sales':
        return <Sales closeChild={() => setActiveComponent(null)} />;
      case 'MSME':
        return <MSME closeChild={() => setActiveComponent(null)} />;
      case 'Team':
        return <Team closeChild={() => setActiveComponent(null)} />;
      case 'Strategic':
        return <Strategic closeChild={() => setActiveComponent(null)} />;
      case 'Financial':
        return <Financial closeChild={() => setActiveComponent(null)} />;
      case 'Business':
        return <Business closeChild={() => setActiveComponent(null)} />;
      case 'Supervisory':
        return <Supervisory closeChild={() => setActiveComponent(null)} />;
      case 'Inteligence':
        return <Inteligence closeChild={() => setActiveComponent(null)} />;
      case 'Risk':
        return <Risk closeChild={() => setActiveComponent(null)} />;
      default:
        return null;
    }
  };
  // 
  return (
    <div className='services'>
        <div className="services-top">
            <img src={wf} alt="Team building" />
        </div>
        <h1>What we do</h1>
        <h2>What we do</h2>

        <button className='back-btn' onClick={handleNavigate}>Back Home</button>
        <div className="services-row">
          <div className="services-col" onClick={() => setActiveComponent('Leadership')}>
            <p>Leadership & Management</p>
          </div>
          <div className="services-col" onClick={() => setActiveComponent('Customer')}>
            <p>Customer Experience</p>
          </div>
          <div className="services-col" onClick={() => setActiveComponent('Sales')}>
            <p>Sales & Marketing</p>
          </div>
          <div className="services-col" onClick={() => setActiveComponent('MSME')}>
            <p>MSME & Entrepreneurship</p>
          </div>
          <div className="services-col" onClick={() => setActiveComponent('Team')}>
            <p>Team Building</p>
          </div>
        </div>

        <div className="services-row">
          <div className="services-col" onClick={() => setActiveComponent('Strategic')}>
            <p>Strategic Management</p>
          </div>
          <div className="services-col" onClick={() => setActiveComponent('Financial')}>
            <p>Financial Literacy</p>
          </div>
          <div className="services-col" onClick={() => setActiveComponent('Business')}>
            <p>Business Growth & Development</p>
          </div>
          <div className="services-col" onClick={() => setActiveComponent('Supervisory')}>
            <p>Supervisory Skill</p>
          </div>
          <div className="services-col" onClick={() => setActiveComponent('Inteligence')}>
            <p>Emotional Intelligence</p>
          </div>
        </div>

        <div className="services-row">
          <div className="services-col" onClick={() => setActiveComponent('Risk')}>
            <p>Risk Management</p>
          </div>
        </div>

        {/* Render the active component */}
        {renderComponent()}
    </div>
  )
}

export default Services;