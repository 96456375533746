import './services.css';

function Sales({closeChild}) {
  return (
      <div className= 'leadership'>
        <i class="bi bi-x-square-fill" onClick={closeChild}></i> 
        <h3>Sales & Marketing</h3>
        <p> We equip teams with strategies to boost sales and optimize marketing efforts for maximum impact.</p>
      </div>
  )
}

export default Sales;