import './services.css';

function Leadership({closeChild}) {
  return (
      <div className= 'leadership'>
        <i class="bi bi-x-square-fill" onClick={closeChild}></i> 
        <h3>Leadership & Management</h3>
        <p>We empower leaders with skills to effectively manage teams and drive organizational success.</p>
      </div>
  )
}

export default Leadership;