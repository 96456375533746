import './services.css';

function Inteligence({closeChild}) {
  return (
      <div className= 'leadership'>
        <i class="bi bi-x-square-fill" onClick={closeChild}></i> 
        <h3>Emotional Intelligence</h3>
        <p>We help individuals develop self-awareness and interpersonal skills to enhance workplace relationships.</p>
      </div>
  )
}

export default Inteligence;