import React from "react";
import { useNavigate } from "react-router-dom";
import "./moreServices.css";
// import wf from "../../images/boardroom2.jpg";

function MoreServices() {
  const navigate = useNavigate();
  const handleNavigate = () => {
    navigate("/");
  };
  return (
    <div className="more-services">
      <div className="more-service-cover">
        <h1>More Services</h1>
        <button className="back-btn" onClick={handleNavigate}>Back Home</button>
        <div className="more-services-body">
          <h3>Leadership & Management</h3>
          <p>We empower leaders with skills to effectively manage teams and drive organizational success.</p>

          <h3>Customer Experience</h3>
          <p>Our training enhances the customer journey, helping businesses build loyalty and satisfaction.</p>

          <h3>Sales & Marketing</h3>
          <p>We equip teams with strategies to boost sales and optimize marketing efforts for maximum impact.</p>

          <h3>MSME & Entrepreneurship</h3>
          <p>Tailored programs to support MSMEs and entrepreneurs in achieving sustainable business growth.</p>

          <h3>Team Building</h3>
          <p>We foster collaboration and strong team dynamics to improve workplace productivity and morale.</p>

          
        6. **Strategic Management**: Our training helps organizations align their strategies with long-term goals for success.
        7. **Financial Literacy**: We provide essential financial knowledge to enhance decision-making and business growth.
        8. **Business Growth & Development**: We offer insights and tools to help businesses expand and capitalize on growth opportunities.
        9. **Supervisory Skills**: Our programs prepare supervisors to lead effectively and manage daily operations smoothly.
        10. **Emotional Intelligence**: We help individuals develop self-awareness and interpersonal skills to enhance workplace relationships.
        11. **Risk Management**: Our training focuses on identifying and mitigating risks to safeguard business operations.
                    
        </div>
      </div>
    </div>
  );
}

export default MoreServices;
