import React from "react";
import { useNavigate } from "react-router-dom";
import "./corevalues.css";
import wf from "../../images/boardroom2.jpg";

function Corevalues() {
  const navigate = useNavigate();
  const handleNavigate = () => {
    navigate("/");
  };
  return (
    <div className="corevalues">
      <div className="services-top">
        <img src={wf} alt="Team building" />
      </div>
      <h1>Core Values</h1>
      <button className="back-btn" onClick={handleNavigate}>Back Home</button>
      <div className="services-row">
        <div className="services-col">
          <h2>Integrity</h2>
          <p>We uphold the highest standards of honesty and transparency in all{" "}</p>
        </div>
        <div className="services-col">
          <h2>Innovation</h2>
          <p>We embrace creativity and seek new solutions to drive progress and excellence.</p>
        </div>
        <div className="services-col">
          <h2>Teamwork</h2>
          <p>We collaborate effectively to achieve common goals and support one
            another.</p>
        </div>
        <div className="services-col">
          <h2>Efficiency</h2>
          <p>We optimize resources and processes to deliver results swiftly and
            effectively.</p>
        </div>
        <div className="services-col">
          <h2>Reliability</h2>
          <p>We are dependable, consistently delivering on our promises and
            commitments.</p>
        </div>
      </div>
    </div>
  );
}

export default Corevalues;
