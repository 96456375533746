import './services.css';

function Supervisory({closeChild}) {
  return (
      <div className= 'leadership'>
        <i class="bi bi-x-square-fill" onClick={closeChild}></i> 
        <h3>Supervisory Skills</h3>
        <p>Our programs prepare supervisors to lead effectively and manage daily operations smoothly</p>
      </div>
  )
}

export default Supervisory;