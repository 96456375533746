import './services.css';

function Strategic({closeChild}) {
  return (
      <div className= 'leadership'>
        <i class="bi bi-x-square-fill" onClick={closeChild}></i> 
        <h3>Strategic Management</h3>
        <p>Our training helps organizations align their strategies with long-term goals for success.</p>
      </div>
  )
}

export default Strategic;