import React from 'react'
import Home from '../home/Home'
import Consultancy from '../consultancy/Consultancy'
import { Route, Routes } from 'react-router-dom'
import Services from '../services/Services'
import Corevalues from '../corevalues/Corevalues'
import MoreServices from '../more-services/MoreServices'
import Training from '../training/Training'

function Main() {
  return (
    <div className='main'>
        <Routes>
            <Route path='/' element={ <Home /> } />                     
            <Route path='/consultancy' element={ <Consultancy /> } />
            <Route path='/services' element={ <Services /> } />
            <Route path='/core-values' element={ <Corevalues /> } />
            <Route path='/more-services' element={ <MoreServices /> } />
            <Route path='/training' element={ <Training /> } />
        </Routes>
    </div>
  )
}
export default Main;