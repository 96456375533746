import React from 'react';
import { useNavigate } from 'react-router-dom';
import './consultancy.css';
import meeting from '../../images/meeting.png';

function Consultancy() {
  const navigate = useNavigate();
  const handleNavigate = () => {
    navigate('/');
  }
  return (
    <div className='consultancy'>
        <h1>Consultancy</h1>
        <div className="home-row">
            <div className="home-col">
                <p>At Sharepoint Training & Consultancy, we specialize in sourcing critical business information for local and international investors, focusing on managerial and liaison services. Every organization, regardless of size, relies on human capital development to enhance efficiency, reduce costs, expand customer bases, and improve retention. However, maintaining this investment can be both costly and complex. Our consultancy services are designed to provide high-quality, cost-effective solutions tailored to meet the unique support needs of businesses, while contributing to their role in achieving Vision 2030.</p>
            </div>
            <div className="home-col">
                <img src={meeting} alt="Consultation" />
            </div>
        </div>
        <button className='back-btn' onClick={handleNavigate}>Back Home</button>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 220"><path fill="#08B6DD" fill-opacity="1" d="M0,224L48,202.7C96,181,192,139,288,138.7C384,139,480,181,576,165.3C672,149,768,75,864,74.7C960,75,1056,149,1152,165.3C1248,181,1344,139,1392,117.3L1440,96L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"></path></svg>
    </div>
  )
}
export default Consultancy;