import './services.css';

function Business({closeChild}) {
  return (
      <div className= 'leadership'>
        <i class="bi bi-x-square-fill" onClick={closeChild}></i> 
        <h3>Business Growth & Development</h3>
        <p>We offer insights and tools to help businesses expand and capitalize on growth opportunities.</p>
      </div>
  )
}

export default Business;