import './services.css';

function Financial({closeChild}) {
  return (
      <div className= 'leadership'>
        <i class="bi bi-x-square-fill" onClick={closeChild}></i> 
        <h3>Financial Literacy</h3>
        <p> We provide essential financial knowledge to enhance decision-making and business growth.</p>
      </div>
  )
}

export default Financial;